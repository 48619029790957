$color-white: #fff;
$color-gray-100: #f8f8f8;
$color-gray-200: #f2f2f2;
$color-gray-300: #e7e7e7;
$color-gray-400: #cecece;
$color-gray-500: #b1b1b1;
$color-gray-600: #969696;
$color-gray-700: #585757;
$color-gray-800: #303030;
$color-gray-900: #1c1c1c;
$color-black: #000;

$color-primary: #f98600;
$color-primary-hover: #fa9e33;
$color-primary-pressed: #c76b00;
$color-primary-border: #fee7cc;
$color-primary-background: #fff4e8;

$color-blue-base: #0085ff;
$color-blue-hover: #339dff;
$color-blue-pressed: #1272cc;
$color-blue-border: #cce7ff;
$color-blue-background: #e5f3ff;

$color-green-base: #00ba34;
$color-green-hover: #33c85d;
$color-green-pressed: #00952a;
$color-green-border: #ccf1d6;
$color-green-background: #e5f8eb;

$color-red-base: #e92c2c;
$color-red-hover: #ed5656;
$color-red-pressed: #ba2323;
$color-red-border: #fbd5d5;
$color-red-background: #ffebeb;

$color-yellow-base: #facc15;
$color-yellow-hover: #fbd644;
$color-yellow-pressed: #c8a311;
$color-yellow-border: #fef5d0;
$color-yellow-background: #fefae8;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
$padding-x: 16px;

$border-radius-xxs: 2px;
$border-radius: 4px;

$transition-duration: 0.3s;
$transition-base: all $transition-duration ease-in-out;

$font-family: 'Inter', 'Segoe UI', 'Roboto', 'Arial', sans-serif;

$font-family-secondary: 'Droid Sans Mono', monospace;

$box-shadow-dropdown: 0px 6px 12px 0px rgba(0, 0, 0, 0.08);
