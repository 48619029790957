@mixin text-colors() {
  @each $color,
    $value
      in (
        'gray-100': $color-gray-100,
        'gray-200': $color-gray-200,
        'gray-300': $color-gray-300,
        'gray-400': $color-gray-400,
        'gray-500': $color-gray-500,
        'gray-600': $color-gray-600,
        'gray-700': $color-gray-700,
        'gray-800': $color-gray-800,
        'gray-900': $color-gray-900,
        'primary': $color-primary
      )
  {
    .text-#{$color} {
      color: $value;
    }
  }
}

@include text-colors();
