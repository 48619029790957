@import 'src/styles/scss/utils/variables';

.ant-alert {
  border: 0;
  padding: 22px 16px 22px 52px;
}

.ant-alert-error {
  background-color: $color-red-background;

  .ant-alert-message,
  .ant-alert-icon {
    color: $color-red-base;
  }
}

.ant-alert-success {
  background-color: $color-green-background;

  .ant-alert-message {
    color: $color-green-base;
  }
}

.ant-alert-warning {
  background-color: $color-yellow-background;

  .ant-alert-message,
  .ant-alert-icon {
    color: $color-primary;
  }
}

.ant-alert-info {
  background-color: $color-gray-300;

  .ant-alert-message,
  .ant-alert-icon {
    color: $color-gray-600;
  }
}

.ant-alert-icon {
  top: initial;
}
