.ant-dropdown {
  .ant-dropdown-menu {
    border: 1px solid $color-gray-200;
    border-radius: $border-radius;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.08);
    font-family: $font-family;
    padding: 8px 0;

    .ant-dropdown-menu-item {
      color: $color-gray-700;
      padding: 0;
    }

    .ant-dropdown-menu-item-group-title {
      color: $color-gray-600;
      padding: 12px;
    }

    .ant-dropdown-menu-item-group-list {
      margin: 0;
    }
  }
}
