.dropdown2 {
  $this: &;

  min-width: 185px !important;

  &__link {
    color: $color-gray-700;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px;
    transition: $transition-base;
    width: 100%;

    &:hover {
      background-color: $color-primary-background;
      color: $color-primary-pressed !important;
    }

    &--active,
    #{$this} .ant-dropdown-menu .ant-dropdown-menu-title-content > &--active {
      background-color: $color-gray-100;
      color: $color-primary !important;

      &:hover {
        color: $color-primary-pressed !important;
      }
    }
  }

  &__block {
    & + & {
      border-left: 1px solid $color-gray-200;
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &--header {
    //.ant-dropdown-menu {
    //display: grid;
    //grid-template-columns: repeat(3, minmax(0, 1fr));
    //width: 480px;
    //}
  }
}
