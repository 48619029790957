@import 'utils/variables';
@import 'utils/breakpoints';
@import 'base/reset';
@import 'base/base';
@import 'fonts/index';
@import 'vendors/antd.alert';
@import 'vendors/antd.button';
@import 'vendors/antd.calendar';
@import 'vendors/antd.drawer';
@import 'vendors/antd.dropdown';
@import 'vendors/antd.message';
@import 'vendors/antd.modal';
@import 'vendors/antd.popover';
@import 'vendors/antd.radio';
@import 'vendors/antd.segmented';
@import 'vendors/antd.table';
@import 'vendors/antd.tooltip';
@import 'vendors/charts';
@import 'blocks/dropdown2';
@import 'blocks/text';

body {
  background-color: $color-gray-100;
  font-family: $font-family;
}

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

main {
  flex: 1;
}

svg {
  vertical-align: middle;
}

a:not([href], [class]) {
  color: inherit;
  text-decoration: none;
}

.overflow-two-lines {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
