* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ol,
ul {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

li {
  display: block;
}

dl {
  margin-bottom: 0;
  margin-top: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin-bottom: 0;
  margin-top: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
}

strong {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  border: 0;
  box-sizing: content-box;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}
