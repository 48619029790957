@import 'src/styles/scss/utils/variables';

.ant-tooltip {
  $tooltip-bg-color: rgba($color-black, 0.75);
  $tooltip-padding: 16px;

  max-width: 292px;

  &.ant-tooltip-placement-top,
  &.ant-tooltip-placement-bottom {
    .ant-tooltip-content {
      padding: 0 $tooltip-padding;

      @at-root .ant-tooltip {
        max-width: calc(292px + #{$tooltip-padding} * 2);
      }
    }
  }

  .ant-tooltip-inner {
    background-color: $tooltip-bg-color;
    border-radius: $border-radius;
    box-shadow: none;
    font-family: $font-family;
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
    text-align: center !important;
  }

  .ant-tooltip-arrow {
    &::before {
      background: $tooltip-bg-color;
    }

    &::after {
      box-shadow: none;
    }
  }
}
