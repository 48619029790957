@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  src:
    url('../../fonts/Inter/InterVariable.woff2') format('woff2'),
    url('../../fonts/Inter/InterVariable.ttf') format('truetype');
}

@font-face {
  font-family: 'Droid Sans Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Droid Sans Mono'), local('DroidSansMono'),
    url('../../fonts/droidsansmono/droidsansmono.woff2') format('woff2'),
    url('../../fonts/droidsansmono/droidsansmono.woff') format('woff'),
    url('../../fonts/droidsansmono/droidsansmono.ttf') format('truetype');
}
