.ant-table-wrapper .ant-table{
  font-family: $font-family;
}

.ant-table-cell {
  &--no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &--toggle {
    padding-right: 8px !important;
    padding-left: 0 !important;
  }

  &--details {
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
}
