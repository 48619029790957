.ant-message {
  .ant-message-custom-content {
    align-items: center;
    display: flex;
  }

  .anticon {
    top: 0;
  }
}
