.ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-range .ant-calendar-in-range-cell::before,
.ant-calendar-month-panel-month:hover,
.ant-calendar-date:hover,
.ant-calendar-decade-panel-decade:hover,
.ant-calendar-time-picker-select li:hover {
  background: $color-primary-background;
}

.ant-calendar-today .ant-calendar-date {
  color: $color-primary;
  border-color: $color-primary-border;
}

.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: $color-primary;
  color: $color-white !important;

  &:hover {
    background: $color-primary;
  }
}

.ant-calendar .ant-calendar-ok-btn {
  align-items: center;
  background: $color-primary;
  border: 0;
  border-radius: $border-radius;
  color: $color-white !important;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  line-height: 20px;

  &:hover {
    background: $color-primary-hover;
  }
}

.ant-calendar-header a:hover {
  color: $color-primary;
}

.ant-calendar-decade-panel-decade:hover,
.ant-calendar-year-panel-year:hover {
  color: $color-primary;
  background: $color-primary-background;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $color-primary-border;
}

.ant-calendar-time-picker-select-option-selected,
.ant-calendar-time-picker-select li:focus {
  color: $color-primary;
}
