@import 'src/styles/scss/utils/variables';

.ant-segmented {
  color: $color-gray-600;
  font-size: 12px;
  font-weight: 500;
  background-color: $color-gray-100;
  padding: 4px;

  .ant-segmented-group {
    gap: 4px;
  }

  .ant-segmented-item {
    border-radius: 2px;

    &:hover:not(.ant-segmented-item-selected):not(
        .ant-segmented-item-disabled
      )::after {
      background-color: transparent;
    }

    &:active:not(.ant-segmented-item-selected):not(
        .ant-segmented-item-disabled
      )::after {
      background-color: transparent;
    }

    &:hover:not(.ant-segmented-item-selected):not(
        .ant-segmented-item-disabled
      ) {
      background-color: $color-white;
      color: $color-gray-600;
    }
  }

  .ant-segmented-item-selected {
    background-color: $color-white;
    box-shadow: none;
    color: $color-primary;
  }

  .ant-segmented-item-label {
    min-height: 24px;
    line-height: 24px;
    padding: 0 8px;
  }

  .ant-segmented-thumb {
    background-color: #ffffff;
    box-shadow: none;
    border-radius: 2px;
  }

  .ant-segmented-item-disabled,
  .ant-segmented-item-disabled:hover,
  .ant-segmented-item-disabled:focus {
    cursor: default;
  }
}
