@import 'src/styles/scss/utils/variables';

:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: #{map-get($grid-breakpoints, md)};
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;

  --header-height: 0px;
}
