@import 'src/styles/scss/utils/variables';
@import 'src/styles/scss/utils/breakpoints';

.ant-popover {
  font-family: $font-family;
  max-width: 344px;

  @include media-breakpoint-up(md) {
    max-width: 400px;
  }

  .ant-popover-inner {
    border-radius: $border-radius;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.10);
  }
}
