.ant-modal-content {
  max-width: 400px;
}

.ant-modal-header,
.ant-modal-body {
  padding: 16px;
}

.ant-modal-body {
  text-align: center;
}