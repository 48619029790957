.ant-radio-group {
  text-align: right;
}

.ant-radio-button-wrapper {
  border: 1px solid $color-gray-300;
  border-left: 0;
  color: $color-gray-700;
  font-size: 14px;
  height: auto;
  line-height: 20px;
  padding: 7px 24px;

  &:hover {
    color: $color-primary;
  }

  &:first-child {
    border-left: 1px solid $color-gray-300;
  }

  &-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      &:focus-within {
        outline: none;
      }

      &,
      &:first-child {
        background: transparent;
        border-color: $color-primary;
        box-shadow: -1px 0 0 0 $color-primary;
        color: $color-primary;

        &:hover {
          border-color: $color-primary;
          box-shadow: -1px 0 0 0 $color-primary;
          color: $color-primary;
        }
      }
    }
  }
}
