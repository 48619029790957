
.smart-charts-account-grows,
.smart-charts--transaction-history {
  .highcharts-point {
    fill: $color-primary;
  }

  .highcharts-graph {
    stroke: $color-primary;
  }

  .highcharts-axis-labels.highcharts-yaxis-labels text:nth-child(even) {
    display: none;
  }

  .highcharts-menu-item:hover {
    background: $color-primary-hover !important;
  }
}

//.highcharts-axis-labels.highcharts-yaxis-labels text:nth-child(1) {
// transform: translateX(-40px);
//}
